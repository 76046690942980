.container {
    flex: 1;

    margin-top: 2rem;
    padding-top: 2rem;

    h4 {
        filter: drop-shadow(2px 2px black);
    }
}

.innerContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 1200px) {
    .container {
        margin-top: 0;
        padding-top: 0;
    }

    .innerContainer {
        margin: 0;
    }
}
