.button {
    display: block;
    padding: 1rem;
    border: solid 2px var(--color-primary, black);
    background-color: var(--color-secondary, white);
    color: var(--color-primary, black);
    width: fit-content;
    border-radius: 1rem;
}

.button:hover {
    border: solid 2px var(--color-secondary, white);
    background-color: var(--color-primary, black);
    color: var(--color-secondary, white);
}
