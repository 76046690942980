.navBar {
    display: flex;
    position: fixed;
    top: 0;
    height: var(--navHeight);
    width: 100%;
    background-color: transparent;

    color: white;
    z-index: calc(var(--maxZ) - 3);
}

.activeLink {
    border-bottom: currentColor 1px solid !important;
    color: white;
    font-weight: 700;
}

.linkContainer {
    display: flex;

    align-items: stretch;

    width: 100%;
    margin: 0.5rem 0;
}

.link {
    border: solid 2px transparent;
    list-style-type: none;
    margin-left: 1rem;
    line-height: 1.1;
    max-width: 100px;
}

.navLink {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    height: 100%;
}

.button {
    // display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    display: none;
    flex-direction: column;
    justify-content: space-between;

    height: 1.5rem;
    width: 3rem;

    .buttonBar {
        height: 3px;
        width: 100%;
        border-radius: 5px;
        background-color: currentColor;
        transition: all 0.5s ease;
    }
}

@media (max-width: 1100px) {
    .button {
        display: flex;
    }

    .navBar {
        flex-direction: column;
        height: auto;
        min-height: var(--navHeight);
    }

    .linkContainer {
        display: none;
        flex-direction: column;
        padding: 0;
        margin: 0;
        margin-top: var(--navHeight);

        background-color: var(--color-primary-light);
        color: var(--color-primary);
    }

    .link {
        border: 2px solid var(--color-primary);
        border-left: none;
        border-right: none;
        max-width: initial;
        height: 50px;
        margin: 0;
    }

    .activeLink {
        background-color: hsla(var(--raw-primary), 0.5);
        color: var(--color-secondary);
        border: none !important;
    }

    .linkContainer.active {
        display: flex;
    }

    .navBar.active {
        background-color: hsla(var(--raw-primary-dark), 0.5);
        z-index: var(--maxZ);
        .linkContainer {
            display: flex;
        }

        .buttonBar:first-of-type {
            transform: rotate(45deg) translate(33%);
        }

        .buttonBar:nth-of-type(2) {
            transform: translateX(200%);
        }

        .buttonBar:last-of-type {
            transform: rotate(-45deg) translate(33%);
        }
    }
}
