.supportGroup {
    margin-top: 1rem;
    width: 100%;
    text-align: center;

    //Temporary measure
    * {
        color: white !important;
    }
}
