img {
    display: block;
    width: 100%;
}

span {
    display: block;
}

html {
    scroll-behavior: smooth;
    // scroll-snap-type: y mandatory;
    // scroll-snap-stop: normal;
}

h1,
h2,
h3,
h4,
h5 {
    text-align: center;
    font-weight: 700;
}

h2 {
    text-align: center;
    font-weight: 700;
}

a {
    text-decoration: none;
    color: currentColor;
}

button {
    outline: none;
    box-shadow: none;
}

a:hover,
button:hover {
    cursor: pointer;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
    overflow-wrap: break-word;
}

main {
    width: 100%;
    overflow-x: hidden;
}

// Scrollbar
::-webkit-scrollbar {
    width: 1rem;
    border-left: solid 1px var(--color-primary);
    background-color: transparent;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(15px);
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px white;
    border: 2px solid transparent;
    border-left: 3px solid transparent;
}

// font-awesome reapply
.fa::before,
.fas::before {
    font-weight: 900;
}
