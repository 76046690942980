.container {
    position: fixed;
    top: 0;

    width: var(--width, 100%);
    height: var(--height, 100%);
    transition: 0.5s;
    z-index: 1;
}

.hide-left {
    transform: translateX(-100%);
}

.hide-right {
    transform: translateX(100%);
}

.hide-top {
    transform: translateY(-100%);
}

// Messy CSS
.onHero {
    // a:hover {
    //     background-color: var(--color-white-always);
    //     border-color: var(--color-primary);
    //     color: var(--color-primary);
    // }

    // & > :first-child {
    //     background-color: transparent;
    //     border: none;

    //     a {
    //         background-color: transparent;
    //         border-color: transparent;
    //         color: var(--color-white-always);
    //     }
    // }
}
