.section:first-of-type {
    border-top: none;
}

.section {
    padding: 4rem 0;
    padding-top: calc(var(--navHeight) + 1.5rem);
    border-top: solid 2px var(--color-primary);
    background-color: var(--color-secondary);
    margin: 0 2.5rem;
}

.sectionContent {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
}

.fullscreen {
    min-height: 100vh;
}

.center-vertically {
    display: flex;
    align-items: center;
}

.no-margin {
    margin: 0;
}

.transparent {
    background-color: transparent;
    border: none;
}

.primary {
    background-color: var(--color-primary-light);
}

@media all and (min-width: 1200px) {
    .sectionContent {
        width: calc(100% - 100px * 2);
        max-width: 1200px;
        padding: 0 1.5rem;
    }
}
