.eventsContainer {
    text-align: center;

    div {
    }
}

.header {
    padding-bottom: 2.5rem;
}

.header:nth-of-type(2) {
    padding: 2rem 0;
}

.imageLinkHeader {
    filter: drop-shadow(2px 2px black) !important;
}
