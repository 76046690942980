.container {
    display: flex;
    flex-direction: column;

    flex: 1;
    padding: 1rem 0;

    font-weight: 700;

    background-color: var(--color-primary);
    color: var(--color-secondary);
    text-shadow: var(--color-primary-dark) 1px 1px;
}

.container:nth-of-type(2n + 1) {
    background-color: var(--color-primary-dark);
    text-shadow: var(--color-primary-darkest) 1px 1px;
}

.container:not(:first-of-type) {
    border-top: solid 1px white;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 50px;
}

.label,
.info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    padding: 1rem;
    border-bottom: solid 1px;
    text-transform: uppercase;
    font-size: 1.25rem;
}

.info {
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
}

.info:not(:first-of-type) {
    border-top: solid 1px currentColor;
}

.description {
    padding: 0.5rem;
}

.time {
    width: 80px;
    text-align: right;
    padding: 0 1rem;
    white-space: nowrap;
}

@media screen and (min-width: 1200px) {
    .container {
        flex-direction: row;
    }

    .label {
        min-width: 100px;
        padding: 1rem;
        padding-right: 1rem;
        border: none;
        border-right: solid 1px;
        margin-right: 1rem;
        text-transform: capitalize;
    }

    .description {
        padding: 0 1rem;

        flex: 1;
    }

    .infoContainer {
        flex: 1;
        margin-right: 1rem;
        justify-content: center;
    }

    .time {
        text-align: left;
        align-self: flex-start;
    }
}
