.hero {
    position: relative;
    height: 100%;

    text-align: center;

    border-top: none;

    h1 {
        position: absolute;
        top: 25%;
        left: 52%;

        padding: 0;
        text-align: center;
        white-space: nowrap;

        text-transform: uppercase;
        transform: translate(-50%, -50%);

        z-index: calc(var(--maxZ) - 4);
    }

    a {
        text-transform: uppercase;
    }

    i::after {
        padding: 0;
        padding-left: 1rem;
    }

    img {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.heroLinks {
    display: flex;
    position: absolute;
    flex-direction: row;
    bottom: 10%;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translate(-50%, -50%);
    z-index: calc(var(--maxZ) - 4);

    flex-direction: column;

    a {
        margin: 0.3rem;
        flex: 1;
        width: 200px;
    }
}

.contentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    max-width: 800px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 1rem;
}

@media all and (max-width: 1200px) {
    .contentContainer {
        max-width: 800px;
    }
}
