.hero {
    height: 100vh;

    text-align: center;

    background-size: cover;
    background-position: center;

    border-top: none;
}

.logo-container {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: var(--navHeight);
    height: calc(100vh - var(--navHeight, 0));
    width: auto;

    transform: translate(-50%, 0%);

    padding: 1rem;

    img {
        position: relative;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
    }

    h1 {
        font-size: clamp(1.3rem, 6vw, 3.5rem);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 4;
        white-space: nowrap;
        letter-spacing: 0.5rem;
        transform: translate(-50%, -39vw);
    }
}

.heroLinks {
    display: flex;
    position: absolute;
    width: 200px;
    left: 50%;
    bottom: 0%;

    display: flex;
    align-items: center;

    font-size: clamp(1.3rem, 3vw, 3.5rem);

    z-index: calc(var(--maxZ) - 3);

    flex-direction: column;

    transform: translate(-50%, -21vh);

    a {
        margin: 0.3rem;
        flex: 1;
        width: 200px;
        text-transform: uppercase;
    }
}

@media all and (min-width: 600px) {
    .heroLinks {
        flex-direction: row;
    }

    .logo-container {
        h1 {
            letter-spacing: 1rem;
            filter: drop-shadow(4px 4px black);
        }
    }
}

@media screen and (min-width: 900px) {
    .logo-container {
        h1 {
            letter-spacing: 1.5rem;
            filter: drop-shadow(5px 5px black);
        }
    }
}

/* The width is greater than the height */
@media screen and (min-width: 100vh) {
    .logo-container {
        h1 {
            font-size: clamp(1rem, 6vh, 3.5rem);
            letter-spacing: 0.5rem;
            filter: drop-shadow(1px 1px black);
            transform: translate(-50%, -38vh);
        }
    }

    @media screen and (min-height: 400px) {
        .logo-container {
            h1 {
                filter: drop-shadow(2px 2px black);
            }
        }
    }

    @media screen and (min-height: 600px) {
        .logo-container {
            h1 {
                letter-spacing: 1rem;
                filter: drop-shadow(4px 4px black);
            }
        }
    }

    @media screen and (min-height: 900px) {
        .logo-container {
            h1 {
                letter-spacing: 1.5rem;
                filter: drop-shadow(5px 5px black);
            }
        }
    }
}
