.screen {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
}

.drawer {
    z-index: calc(var(--maxZ) - 2);
}
