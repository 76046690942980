.container {
    flex: 1;
    padding: 1rem;
    padding-top: 0;

    text-align: center;
}

.imgContainer {
    position: relative;
    padding: 1rem 0;
}

.headerContainer {
    position: absolute;
    top: 20%;
    left: 6%;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 45%;
    width: 60%;

    transform: rotate(7deg);
}

.header {
    text-align: center;
    font-size: 2.2rem;
    background: transparent;
    color: #ea27ed;

    overflow: hidden;
    text-shadow: 2px 2px 5px #ea27ed, -2px -2px 5px #ea27ed,
        2px -2px 5px #ea27ed, -2px 2px 5px #ea27ed;
    -webkit-text-stroke: 1px #f791f9;
}

.image {
    border: solid 2px var(--color-primary);
}

@media screen and (max-width: 1200px) {
    .container {
        padding-top: 2rem;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 770px) {
    .header {
        font-size: 5vw;
    }
}

@media screen and (max-width: 400px) {
    .container {
        padding-top: 2rem;
        margin-top: 2rem;
    }
}
