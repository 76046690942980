.footer {
    position: relative;
    width: 100%;
    height: 100px;
    text-align: center;
    color: var(--color-secondary);

    * {
        font-weight: 700;
    }
}

.footer-content {
    display: flex;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
}

.footer-github {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    justify-content: center;

    i {
        padding-right: 0.5rem;
    }
}

@media screen and (max-width: 600px) {
    .footer-content {
        font-size: 0.8rem;
        width: calc(100% - 100px);
    }
}
