.statCard {
    display: inline-flex;
    flex-direction: column;

    flex: 1;
    width: 100%;
    max-width: 220px;
    height: 300px;

    margin-bottom: 10px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    background-color: var(--color-primary-dark);
}

.text {
    flex: 1.5;
    padding: 0.5rem;
    text-align: center;
    background-color: var(--color-primary-light);
}

.text-header {
    padding: 0.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
}

@media all and (min-width: 500px) {
    .statCard {
        margin: 10px;
    }
}
