.container {
    padding: 2rem 1rem;
    display: inline-flex;

    width: 100%;
    height: 100%;
}

.icon {
    color: var(--color-primary, black);
    height: 100%;
    display: inline;
    margin-right: 2rem;
    align-self: flex-start;
}

.description {
    display: inline-block;
    text-align: left;
    color: var(--color-text, black);
}

.description-header {
    padding-bottom: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.8;
    text-align: left;
    color: var(--color-text, black);
    letter-spacing: 0rem !important;
}

@media (min-width: 1000px) {
    .container {
        max-height: 300px;
        max-width: 300px;
        padding: 1rem;
        margin: 2rem;

        border: none;
    }
}
