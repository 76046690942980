.drawer {
    h2 {
        font-size: 2rem;
        padding: 1rem;
        color: white;
        background-color: hsla(var(--raw-primary-dark), 0.5);
        backdrop-filter: blur(15px);
    }

    a {
        width: 100%;
        border-radius: 0;

        border: none;
        border-top: solid 2px white;
        border-bottom: solid 2px white;
        background-color: hsla(var(--raw-primary-dark), 0.5);
        backdrop-filter: blur(15px);
        color: white;

        font-size: 1.5rem;
    }

    a:first-of-type {
        border-top: solid 4px white;
    }

    a:last-of-type {
        border-bottom: solid 4px white;
    }

    a:hover {
        border: none;
        border-top: solid 2px var(--color-secondary);
        border-bottom: solid 2px var(--color-secondary);
    }
}

.drawerSection {
    padding-bottom: 6px;

    margin-bottom: 1rem;
}

.socialSection {
    i {
        padding-left: 4rem;
        justify-content: flex-start;
        font-size: 1.5rem;
    }
}

@media all and (max-width: 300px) {
    .drawer {
        h2 {
            text-align: left;
        }
    }

    .drawerSection {
        i {
            justify-content: flex-start;
            padding-left: 0;
        }

        a {
            text-align: left;
        }
    }
}
