.drawer {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 350px;
    width: calc(100% - 2rem);
    padding: 0;

    transition: 750ms;
    z-index: calc(var(--maxZ) - 3);

    background-color: hsla(var(--raw-primary-dark), 0.5);
    backdrop-filter: blur(15px);

    font-size: 2rem;
}

.content {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.button {
    position: absolute;
    bottom: 0.5rem;
    height: 4rem;

    width: 2rem;
    border: none;
    background-color: hsla(var(--raw-primary-dark), 0.5);
    backdrop-filter: blur(15px);
    color: var(--color-secondary);
}

.buttonIcon {
    position: absolute;
    transition: 0.4s transform;
    top: 50%;
    transform: translate(0%, -50%) rotate(0);
}

.buttonIcon::before {
    transform-origin: center;
    font-size: 1.8rem;
}

.button.flip {
    .buttonIcon {
        transform: translate(0%, -50%) rotate(180deg);
    }
}

.drawer-left {
    border-right: solid 4px var(--color-secondary);
    transform: translateX(-100%);
    left: 0;

    .button {
        right: 0rem;

        border-left: none;
        transform: translateX(calc(100% + 4px));
        border-bottom-right-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }

    .buttonIcon {
        padding-left: 3px;
        left: 0;
    }
}

.drawer-right {
    border-left: solid 4px var(--color-secondary);
    transform: translateX(100%);
    right: 0;

    .button {
        left: 0;

        border-right: none;
        transform: translateX(calc(-100% - 4px));
        border-bottom-left-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
    }

    .buttonIcon {
        padding-right: 3px;
        right: 0;
    }
}

.active-left {
    left: 0;
    transform: translateX(0);
    z-index: var(--maxZ);
}

.active-right {
    right: 0;
    transform: translateX(0);
    z-index: var(--maxZ);
}
