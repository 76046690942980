.glass-primary {
    background-color: hsla(var(--raw-primary), 0.3);
    backdrop-filter: blur(15px);
}

.glass-white {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
}

.header-ioverse {
    background: linear-gradient(var(--color-primary), white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: white;

    //title font styles
    font-weight: 700;
    text-transform: uppercase;

    filter: drop-shadow(3px 3px black);
}

.header-ioverse-gradient {
    background: linear-gradient(var(--color-primary), white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: white;
    filter: drop-shadow(3px 3px black);
}

@media screen and (min-width: 600px) {
    .header-ioverse {
        letter-spacing: 1rem;
    }
}
