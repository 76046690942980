.container {
    margin: 0 auto;
    position: relative;
    width: 100vw;
    max-width: 500px;

    * {
        outline: none;
        -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
    }

    // Would love to make shows self functioning by using container queries but didn't get it to work
    > div > div:first-of-type {
        flex-direction: column;

        > div:nth-of-type(2) {
            max-height: none;
        }
    }
}

.showContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;

    background-color: rgba(0, 0, 0, 0.8);
    color: rgb(255, 255, 255);
    margin-top: 20px;

    div:first-of-type {
        --sizePercentage: calc(100vw / 2);
    }
}

.arrowRootContainer {
    position: absolute;
    width: fit-content;
    height: 100%;
    top: 0;
    cursor: pointer;
}

.arrowCenterContainer {
    height: 100%;
    transform: translate(0%, 50%);
}

.arrowLeft {
    left: 0;
    transform: translate(-100%, 0px);
    padding-right: 20px;

    img {
        transform: rotate(180deg) translate(0, 50%);
    }
}

.arrowRight {
    right: 0;
    transform: translate(100%, 0px);
    padding-left: 20px;

    img {
        transform: translate(0, -50%);
    }
}

.cssArrowContainer {
    display: flex;
    padding: 10px;
    width: 100%;
    padding: 10px 5px;

    justify-content: space-between;
    position: absolute;

    bottom: 42%;
}

.cssArrowRight {
    width: 0;
    height: 0;
    border-top: 20px solid transparent; /* Adjust size as needed */
    border-bottom: 20px solid transparent; /* Adjust size as needed */
    border-left: 20px solid currentColor; /* Adjust color and size as needed */
}

.cssArrowLeft {
    width: 0;
    height: 0;
    border-top: 20px solid transparent; /* Adjust size as needed */
    border-bottom: 20px solid transparent; /* Adjust size as needed */
    border-right: 20px solid currentColor; /* Adjust color and size as needed */
}

@media all and (min-width: 300px) {
    .cssArrowContainer {
        justify-content: space-between;
        position: absolute;
        bottom: 25%;
        padding: 10px;
    }
}

@media all and (min-width: 500px) {
    .showContainer {
        display: block;
        width: 100%;
    }
}

@media all and (min-width: 1400px) {
    .container {
        width: 1050px;
        max-width: none;

        // Would love to make shows self functioning by using container queries but didn't get it to work
        > div > div:first-of-type {
            flex-direction: row;

            > div:nth-of-type(2) {
                max-height: 281px;
            }
        }
    }

    .arrowRootContainer {
        img {
            height: 70%;
        }
    }
}
