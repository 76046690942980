.section {
    padding: 1rem;
    color: var(--color-secondary);
    background-color: var(--color-primary);
    white-space: pre-wrap;
    text-shadow: var(--color-primary-dark) 1px 1px;
}

.section:nth-of-type(2n) {
    background-color: var(--color-primary-dark);
    text-shadow: var(--color-primary-darkest) 1px 1px;
}

.section:not(:first-of-type) {
    border-top: solid 1px white;
}

.header {
    text-align: center;
    font-weight: 700;
    // text-decoration: underline;
}
