.container {
    max-width: 250px;
    height: auto;
    width: calc(100% - 4px);
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 2px;
    border: solid 2px var(--color-primary);

    a {
        background-color: transparent;
        border: none;
        color: transparent;
    }
}

.screen {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    transition: background-color 0.5s;
    color: transparent;
}

.screen-hide {
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
}

.iconContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    color: transparent;

    i {
        font-size: 3rem;
        display: none;
    }
}

.show {
    i {
        display: block;
        color: hsla(var(--raw-primary), 1);
    }

    a:hover {
        border: none;
        background: none;
    }

    i:hover {
        color: hsla(var(--raw-primary-dark), 0.9);
        border: none;
        background: none;
    }
}

.header {
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    transition: transform 0.1s;
    text-align: center;
    width: 100%;
    padding: 0.5rem;
}

.showHeader {
    transform: translate(0, 0);
}
