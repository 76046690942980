.container {
    display: flex;
    flex-direction: column;
}

.arrowRootContainer {
    width: fit-content;
    height: 50%;
    bottom: 0;
    cursor: pointer;
    padding: 20px;
    img {
        height: 100%;
    }
}

.arrowCenterContainer {
    height: 100%;
    transform: translate(0%, 50%);
}

.arrowLeft {
    left: 0;
    transform: translate(0%, 0px);

    img {
        transform: rotate(180deg) translate(0, 50%);
    }
}

.infoContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex: 1;
}

.arrowRight {
    right: 0;
    transform: translate(0%, 0px);

    img {
        transform: translate(0, -50%);
    }
}
