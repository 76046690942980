.section {
    padding: 1rem;
    color: white;
    border-radius: 1rem;

    p {
        padding: 1rem;
    }

    h3 {
        text-transform: uppercase;
        font-style: italic;
        padding-bottom: 0.5rem;

        a {
            font-weight: 700;
            text-decoration: underline;
        }
    }
}
