.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    font-size: 1.25rem;
    font-weight: 400;
}

.padding::before {
    padding: var(--padding, 0 1rem 0 0);
}

.iconAfter {
    display: flex;
    flex-direction: row-reverse;
}

.iconAfter.padding::before {
    padding: var(--padding, 0 0 0 1rem);
}
