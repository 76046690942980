.padding-bottom {
    padding-bottom: 1rem;
}

.color-white {
    color: white;
}

.color-black {
    color: black;
}

.color-primary-dark {
    color: var(--color-primary-dark);
}

.color-primary {
    color: var(--color-primary);
}

.color-primary-light {
    color: var(--color-primary-light);
}

.bg-primary {
    background-color: var(--color-primary);
}

.bg-primary-light {
    background-color: var(--color-primary-light);
}

.bg-primary-dark {
    background-color: var(--color-primary-dark);
}

.shadow-text-outline {
    letter-spacing: 0.1rem;
    text-shadow: 1.5px 1.5px 0 var(--color-primary-dark, black),
        -1.5px -1.5px 0 var(--color-primary-dark, black),
        1.5px -1.5px 0 var(--color-primary-dark, black),
        -1.5px 1.5px 0 var(--color-primary-dark, black),
        1.5px 1.5px 0 var(--color-primary-dark, black);
}

.fs-900 {
    font-size: clamp(5rem, 8vw + 1rem, 9.375rem);
}
.fs-800 {
    font-size: 6rem;
}
.fs-700 {
    font-size: clamp(2rem, 8vw + 1rem, 3.5rem);
}

.fs-650 {
    font-size: 3rem;
}

.fs-600 {
    font-size: 2rem;
}
.fs-500 {
    font-size: 1.75rem;
}
.fs-400 {
    font-size: 1.25rem;
}
.fs-300 {
    font-size: 1rem;
}
.fs-200 {
    font-size: 0.9rem;
}

.fs-900,
.fs-800,
.fs-700,
.fs-600 {
    line-height: 1.1;
}

.margin-small {
    margin: 1px 0;
}

.margin-medium {
    margin: 0.5rem 0;
}

.header-default {
    padding: 2rem 0;
    text-align: center;
    font-weight: 700;
}

.text-center {
    text-align: center;
}

.container {
    min-height: 100vh;
    max-width: 1200px;
    width: auto;
    padding: 0 1em;
    margin: 0 auto;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

@media screen and (max-width: 400px) {
    .fs-900 {
        font-size: clamp(5rem, 8vw + 1rem, 9.375rem);
    }
    .fs-800 {
        font-size: 6rem;
    }
    .fs-700 {
        font-size: clamp(2rem, 8vw + 1rem, 3.5rem);
    }

    .fs-650 {
        font-size: 2rem;
    }

    .fs-600 {
        font-size: 1.5rem;
    }
    .fs-500 {
        font-size: 1.25rem;
    }
    .fs-400 {
        font-size: 1rem;
    }
    .fs-300 {
        font-size: 0.75rem;
    }
    .fs-200 {
        font-size: 0.6rem;
    }
}
