.banner {
    background-image: url(showBanners.webp);
    background-size: calc(2500px * var(--sizePercentage));
    background-repeat: no-repeat;
    display: block;
    border: 0;
    --sizePercentage: 1;
    --spriteWidth: calc(500px * var(--sizePercentage));
    --spriteHeight: calc(281px * var(--sizePercentage));
    width: var(--spriteWidth);
    height: var(--spriteHeight);
}

.banner-all_king {
    --xPosition: 0;
    --yPosition: 0;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-arcane_academy {
    --xPosition: 1;
    --yPosition: 0;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-astral_academy {
    --xPosition: 2;
    --yPosition: 0;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-bronn {
    --xPosition: 3;
    --yPosition: 0;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-curious_curios {
    --xPosition: 0;
    --yPosition: 1;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-curious_curios_s2 {
    --xPosition: 1;
    --yPosition: 1;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-curious_curios_s3 {
    --xPosition: 2;
    --yPosition: 1;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-dawn_of_ganymede {
    --xPosition: 3;
    --yPosition: 1;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-deadbeats {
    --xPosition: 0;
    --yPosition: 2;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-deadbeats_s2 {
    --xPosition: 1;
    --yPosition: 2;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-deadbeats_s3 {
    --xPosition: 2;
    --yPosition: 2;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-deadbeats_s4 {
    --xPosition: 3;
    --yPosition: 2;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-eye_of_asmodia {
    --xPosition: 0;
    --yPosition: 3;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-goblins_of_io {
    --xPosition: 1;
    --yPosition: 3;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-hunters_of_io {
    --xPosition: 2;
    --yPosition: 3;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-into_the_shadows_breach {
    --xPosition: 3;
    --yPosition: 3;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-into_the_shadows_breach_s2 {
    --xPosition: 0;
    --yPosition: 4;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-into_the_shadows_breach_s3 {
    --xPosition: 1;
    --yPosition: 4;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-mainframe_of_atlas {
    --xPosition: 2;
    --yPosition: 4;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-march_on_faewunder {
    --xPosition: 3;
    --yPosition: 4;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-miss_demeanor {
    --xPosition: 0;
    --yPosition: 5;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-phase_2 {
    --xPosition: 1;
    --yPosition: 5;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-phase_3 {
    --xPosition: 2;
    --yPosition: 5;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-pipe_dreamers {
    --xPosition: 3;
    --yPosition: 5;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-pipe_dreamers_s2 {
    --xPosition: 0;
    --yPosition: 6;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-pipe_dreamers_s3 {
    --xPosition: 1;
    --yPosition: 6;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-radiance {
    --xPosition: 2;
    --yPosition: 6;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-shadow_of_asmodia {
    --xPosition: 3;
    --yPosition: 6;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-spire_of_euclid {
    --xPosition: 4;
    --yPosition: 0;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-altered_ballad {
    --xPosition: 4;
    --yPosition: 1;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-nova_hellscape {
    --xPosition: 4;
    --yPosition: 2;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-tower_of_archimedes {
    --xPosition: 4;
    --yPosition: 3;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}

.banner-under_io {
    background-position: -2000px -1124px;
    --xPosition: 4;
    --yPosition: 4;
    background-position: calc(var(--xPosition) * -1 * var(--spriteWidth))
        calc(var(--yPosition) * -1 * var(--spriteHeight));
}
