.container {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
}

.title {
    padding: 1rem;
}

@media screen and (max-width: 1200px) {
    .container {
        padding-top: 0rem;
        flex-direction: column-reverse;
        align-items: center;
    }

    .title {
        padding: 0rem;
    }
}
